.moderatorControlsContainer {
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 5px;
}
.moderatorControlsTitle {
    font-size: 12px;
    font-weight: bold;
}
.modControlIcon {
    float: left;
}
.modControlText {
    float: left;
    font-weight: bold;
    margin: 6px 0px 0px 12px;
    color: blue;
    font-size: 14px;
}
.modControlBtn {
    padding: 8px;
    margin-right: 40px;
}
.modControlBtn:last-child {
    margin-right: 0px;
}
.modControlBtn:hover {
    background-color: #CCC;
}
.modControlBtn.cancelMod {
    margin-bottom: 0px;
}
.addTicketModal {
    position: absolute;
    border: 1px solid green;
    background-color: #FFFFFF;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 80%;
    padding: 20px;
    box-shadow: 0px 0px 15px #CCC;
}

@media only screen and (min-width: 650px) {
    .becomeModeratorText {
        display: block;
    }
    .addTicketModal {
        width: 50%;
    }
}