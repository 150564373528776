h2 {
    margin-bottom: 45px;
    border-top: 1px solid #000;
    padding-top: 37px;
    font-size: 20px;
}

.selectRoomContainer {
    padding-left: 15px;
}
.sessionSelectContainer {
    display: flex;
    flex-wrap: wrap;
}
.enterRoom {
    padding: 15px 15px;
    border: 1px solid #000000;
    margin-right: 25px;
    margin-bottom: 25px;
    border-radius: 7px;
    cursor: pointer;
    width: 200px;
}
.enterRoom:hover {
    background-color: yellow;
}