.participantScore {
    float: left;
    border-radius: 40px;
    padding: 10px;
    font-size: 20px;
    cursor: pointer;
    width: 60px;
    height: 60px;
    border: 2px solid transparent;
    background-color: #CCC;
    outline: none;
    margin-right: 10px;
    margin-bottom: 18px;
}

.selectedScore {
    border-color: green;
}

@media only screen and (min-width: 650px) {
    .participantScore {
        width: 75px;
        height: 75px;
        font-size: 24px;
    }
}