.App {
    max-width: 1060px;
    margin: auto;
    background-color: #F6F6F6;
    min-height: 1000px;
    padding: 0px 20px;
    position: relative;
}
.pointingPokerHeading {
    float: left;
    font-size: 24px;
    margin: 27px 0px 0px 7px;
}
.themeButtonsContainer {
    float: right;
}
.clockTimersContainer {
    float: right;
    margin-top: 11px;
}
.indiaClock {
    float: left;
    margin-top: 9px;
}
.flagIcon {
    float: left;
}
.timeContainer {
    float: left;
    font-size: 13px;
    margin-left: 5px;
    background-color: #555;
    color: #FFF;
    padding: 5px 7px;
    border-radius: 10px;
    width: 65px;
    text-align: center;
}
.cardsLogo {
    display: none;
    top: 17px;
    left: 10px;
    position: relative;
}
.connectingState {
    text-align: center;
    margin-top: 40px;
}
.spinnerCardGif {
    max-height: 150px;
}
.connError {
    font-size: 20px;
    text-align: center;
    margin-top: 140px;
    font-style: italic;
    color: #666;
}
@media only screen and (min-width: 650px) {
    .cardsLogo {
        display: inline;
    }
    .pointingPokerHeading {
        font-size: 32px;
        margin: 24px 0px 16px 7px;
    }
    .usClock {
        float: left;
    }
    .indiaClock {
        float: left;
        margin-top: 0px;
        margin-left: 25px;
    }
    .clockTimersContainer {
        margin-top: 34px;
    }
}