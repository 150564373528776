.sessionTitleBar {
    background-color: #999;
    color: #FFF;
    padding: 5px;
    font-weight: bold;
    font-size: 18px;
    border-radius: 12px;
    margin-bottom: 15px;
    margin-top: 12px;
}
.sessionTitleText {
    float: left;
    margin: 7px 0px 0px 4px;
}
.backToSessionSelect {
    color: blue;
    padding: 10px 10px 10px 6px;
    cursor: pointer;
    border: 1px solid #999;
    border-radius: 10px;
    float: right;
    font-size: 12px;
    background-color: #999;
}
.backToSessionSelect:hover {
    background-color: #CCC;
}
.participantsListContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-content: flex-start;
}
.participantListTable, .averageScoreTable {
    width: 100%;
    border-collapse: collapse;
    min-width: 315px;
}
.participantListTable td, .averageScoreTable td {
    border-top: 1px solid #CCC;
    border-bottom: 1px solid #CCC;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
.scoreOptionsContainer {
    margin-top: 20px;
    margin-bottom: 20px;
}
.addParticipantAndModeratorControlsRow {
    min-height: 55px;
}
.becomeModeratorContainer {
    text-align: center;
    color: green;
    font-weight: bold;
    cursor: pointer;
    float: right;
    margin: 3px 5px 0px 0px;
}
.becomeModeratorText {
    display: none;
}
.notAvailable {
    opacity: 0.3;
    cursor: not-allowed;
}
@media only screen and (min-width: 650px) {
    .becomeModeratorText {
        display: block;
    }
    .participantListTable, .averageScoreTable {
        max-width: 600px;
    }
    .becomeModeratorContainer {
        margin: -7px 5px 0px 0px;
    }
}