.currentlyPointingContainer {
    border-top: 1px dashed #000000;
    padding: 15px 0px;
}
.currentlyPointingLabel {
    margin-bottom: 5px;
}
.ticketNameContainer {
    padding: 6px 10px 10px 10px;
    height: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.noActiveTicket {
    color: #999;
    font-style: italic;
}
.activeTicketControl {
    display: flex;
    justify-content: space-between;
    background-color: #DDD;
    max-width: 500px;
}
.clearActiveTicket {
    color: blue;
    cursor: pointer;
    padding-left: 10px;
    padding-top: 8px;
    padding-right: 11px;
    font-size: 12px;
    border-left: 2px #999 groove;
}