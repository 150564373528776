.enterTicketForm {

}
.enterTicketLabel {
    margin-bottom: 5px;
}
.enterTicketInputContainer {
    margin-bottom: 10px;
}
.enterTicketInput {
    padding: 7px;
    border: 1px solid #CCC;
    width: 90%;
}
.startPointingBtn {
    display: inline-block;
    padding: 0.3em 1.2em;
    margin: 0 0.3em 0.3em 0;
    border-radius: 2em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Roboto',sans-serif;
    font-weight: bold;
    color: #FFFFFF;
    background-color: #4eb5f1;
    text-align: center;
    transition: all 0.2s;
    border: none;
    cursor: pointer;
}
.startPointingBtn:hover {
    background-color: #4095c6;
}


@media only screen and (min-width: 650px) {
    .enterTicketInput {
        width: 50%;
    }
}