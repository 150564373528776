.addParticipantFormContainer {
    float: left;
    clear: both;
}
.enterPointingSessionInput {
    min-width: 180px;
    max-width: 400px;
    padding: 10px;
}
.enterPointingSessionButton {
    padding: 10px;
    margin-left: 10px;
}
.errorMsg {
    color: red;
    margin: 11px 0px 0px 2px;
}
.notAvailable {
    opacity: 0.35;
}
@media only screen and (min-width: 650px) {
    .addParticipantFormContainer {

    }
}