.participantControlBtns {
    margin-top: 6px;
    height: 15px;
}
.participantName {
    height: 36px;
    padding: 14px 0px 0px 10px;
    margin-right: 102px;
}
.participantNameText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 20px;
    margin-right: 10px;
    margin-left: 10px;
    padding: 14px 0px 11px 0px;
    max-width: 133px;
}
.moderatorBadge {
    background-color: orange;
    margin-right: 10px;
}
.afkStatus {
    background-color: red;
    margin-right: 10px;
}
.clientParticipantBtn {
    font-size: 12px;
    font-weight: bold;
    color: #FFF;
    padding: 5px 7px 5px 7px;
    border-radius: 7px;
    cursor: pointer;
    margin-top: 13px;
    height: 17px;
}
.clientContainer {
    background-color: #FFF;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 53px;
    min-width: 330px;
    margin-right: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 4px #CCC;
}
.clientParticipantContainer {
    background-color: #ffffb3;
}
.participantOption {
    color: blue;
    cursor: pointer;
    float: left;
    font-size: 12px;
}
.participantScoreCol {
    font-weight: bold;
    font-size: 20px;
    background-color: #333;
    color: #FFF;
    padding: 13px 0px 0px 0px;
    margin-left: auto;
    width: 50px;
    text-align: center;
}
.clientParticipantMenuContainer {
    margin-right: 15px;
    margin-top: 16px;
    cursor: pointer;
    position:relative;
}
.participantRemoveCol {
    background-color: #DDD;
    cursor: pointer;
    padding: 17px 10px 0px 10px;
    text-align: center;
}