.participantMenu {
    height: 143px;
    width: 175px;
    background-color: #FFF;
    box-shadow: 0px 0px 5px #888;
    position: absolute;
    top: 23px;
    left: 0px;
    color: blue;
    font-size: 12px;
    text-align: left;
    z-index: 1000;
}
.participantOption {
    cursor: pointer;
    padding: 10px 0px 10px 10px;
    border-bottom: 1px solid #CCC;
}
.participantOption:hover {
    background-color: #DDD;
}