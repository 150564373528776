.scoreboardContainer {
    border-top: 2px dashed green;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.scoreboardModule {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 175px;
    background-color: #FFF;
    padding-left: 10px;
    border-bottom: 1px solid #DDD;
    height: 36px;
}
.scoreboardModuleLabel {
    margin-right: 8px;
    padding-top: 8px;
}
.scoreboardModuleValue {
    background-color: #333;
    color: #FFF;
    padding: 8px 0px;
    font-weight: bold;
    height: 20px;
    width: 50px;
    text-align: center;
}
.scoresBreakdownContainer {
    padding-bottom: 30px;
}
.scoresBreakdownHeading {
    font-weight: bold;
    padding-bottom: 7px;
    text-align: center;
}